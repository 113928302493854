<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold black--text mb-2">
          {{ $t('access_code.one_time_password') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ $t('access_code.enter_one_time_password') }}
        </p>
        <p
          class="ma-0 pt-4 text-center"
          style="font-weight: bold; padding-top: 0px !important;"
        >
          <!-- {{ loginData.type === 'EMAIL' ? loginData.code : formatNumber(loginData.fullPhone) }} -->
          {{ loginData.type == loginData.type_selected && loginData.type === loginData.encode ? (loginData.type == 'PHONE_NUMBER' ? loginData.phone_number : loginData.email) : (loginData.type == 'PHONE_NUMBER' ? formatEmail(loginData.email) : formatPhoneNumber(loginData.phone_number)) }}
        </p>
      </v-card-text>

      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="loginSubmit"
        >
          <v-otp-input
            id="otp-input"
            v-model="accessCode"
            type="number"
            class="code-number ma-auto pl-3"
            :length="totalInput"
            @finish="loginSubmit"
          />

          <!-- <div class="d-flex justify-center align-center">
            <span class="mr-2">{{ t('access_code.didnt_receive') }}</span>
            <v-btn
              class="pa-0 text-center no-bg-hover"
              style="
                text-transform: inherit;
                color: #FF3232;
                min-width: 60px;
              "
              text
              :ripple="false"
              @click="onResend"
            >
              {{ t('tooltip.resend') }}
            </v-btn>
          </div> -->

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            class="mt-6 text-capitalize"
            :disabled="!isFormValid"
          >
            {{ t('access_code.log_in') }}
          </v-btn>

          <div class="d-flex align-center my-4">
            <v-divider></v-divider>
            <span class="mx-4 font-weight-bold">{{ t('access_code.or_code') }}</span>
            <v-divider></v-divider>
          </div>

          <v-btn
            block
            color="primary"
            type="submit"
            rounded
            large
            class="mt-6"
            style="text-transform: none;"
            outlined
            @click="onLoginPassword"
          >
            {{ t('access_code.login_with_password') }}
          </v-btn>
          <div
            class="d-flex"
            style="margin-top: 10px;"
          >
            <v-btn
              :disabled="timeLeft > 0"
              :style="{ opacity: timeLeft > 0 ? 0.6 : 1 }"
              class="mt-1 py-2 no-bg-hover"
              color="primary"
              style="text-transform: none; font-weight: 700; padding-left: 0px; padding-right: 0px;"
              text
              @click="onLoginCode"
            >
              {{ t('access_code.didnt_receive') }}
            </v-btn>
            <span
              v-if="timeLeft > 0"
              class="ml-2"
              style="font-weight: bold; padding-top: 12px !important; margin-left: 5px !important; padding-left: 0px !important;"
            >
              {{ t('access_code.in') }} {{ formatTime(timeLeft) }} {{ t('access_code.seconds') }}
            </span>
          </div>
          <div
            class="d-flex"
            style="margin-top: 0px;"
          >
            <v-btn
              class="mt-1 py-2 no-bg-hover"
              color="primary"
              style="text-transform: none; font-weight: 700; padding-left: 0px;"
              text
              @click="onAnotherAccount"
            >
              {{ $t('access_code.sign_in_another_account') }}
            </v-btn>
          </div>

          <!-- <div class="d-flex justify-center">
            <v-btn
              class="mt-1 py-2 no-bg-hover"
              color="primary"
              style="text-transform: inherit;"
              text
              @click="onChangeLogin"
            >
              {{ t('access_code.or_password') }}
            </v-btn>
          </div> -->
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, computed, getCurrentInstance, onMounted, onBeforeUnmount } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import store from '@/store'
import { useUtils } from '@core/libs/i18n'
import { formatPhoneNumber, formatEmail } from '@core/utils'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import usePhone from '@core/utils/usePhone'
import { sendCodeSMSUser, sendCodeEmailUser } from '@api'
import router from '@/router'

export default {
  setup() {
    const { t } = useUtils()
    const { encrypt } = useCryptoJs()
    const { formatNumber } = usePhone()
    const { logData, currentLogData } = useLogData()
    const vm = getCurrentInstance().proxy

    const form = ref(null)
    const loginData = ref({
      code: '',
      phoneCode: '',
      fullPhone: '',
      type: '',
      exists: '',
      loginRoute: 'LOGIN_PAGE',
      password: null,
      type_selected: '',
      phone_number: '',
      email: '',
    })

    const totalInput = ref(4)
    const completed = ref(false)
    const accessCode = ref('')
    const timeLeft = ref(60)
    let timer = null

    // Función para iniciar el contador regresivo
    const startTimer = () => {
      timer = setInterval(() => {
        if (timeLeft.value > 0) {
          timeLeft.value -= 1
        } else {
          clearInterval(timer)
          timer = null
        }
      }, 1000)
    }

    const onLoginCode = async () => {
      let respSend = null
      let info = null
      let encryptBody = null
      if (logData.value.type_selected === 'PHONE_NUMBER') {
        info = {
          phone_number: logData.value.fullPhone,
        }
        encryptBody = encrypt(info)
        respSend = await sendCodeSMSUser(encryptBody)
      } else {
        info = {
          email: logData.value.code,
        }
        encryptBody = encrypt(info)
        respSend = await sendCodeEmailUser(encryptBody)
      }

      if (respSend.ok) {
        timeLeft.value = 60
        startTimer()
      }
    }

    // Limpiamos el intervalo cuando se destruya el componente para evitar fugas de memoria
    onBeforeUnmount(() => {
      if (timer) {
        clearInterval(timer)
      }
    })

    const onLoginPassword = async () => {
      router.push({ name: 'auth-login' })
    }

    const formatTime = time => time.toString()

    const onAnotherAccount = async () => {
      /* logData.value.type = ''
      logData.value.code = ''
      logData.value.fullPhone = ''
      logData.value.phoneCode = ''
      await updateLogData(logData.value) */
      router.push({ name: 'auth-login-phone-email' })
    }

    const isFormValid = computed(() => {
      if (accessCode.value && accessCode.value.length === totalInput.value) return true

      return false
    })

    const loginSubmit = async () => {
      if (accessCode.value.length === totalInput.value) {
        const body = {
          code: loginData.value.type === 'EMAIL' ? loginData.value.email : loginData.value.fullPhone || loginData.value.phone_number,
          type: loginData.value.type,
          join_game: loginData.value.joinGame,
          match_code: loginData.value.matchCode,
          login_route: 'ACCESS_CODE_PAGE',
          password: accessCode.value,
        }
        const encryptBody = encrypt(body)

        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]
        vm.$ability.update(userAbility)

        // remove team
        store.dispatch('auth/loginUserAction', {
          data: encryptBody,
          redirect: logData.value.redirect,
          facility: logData.value.facility,
          field: logData.value.field,
          date: logData.value.date,
          time: logData.value.time,
          slot: logData.value.slot,
          pay: logData.value.pay,
          reservationCode: logData.value.reservationCode,
          showPay: logData.value.showPay,
          team: logData.value.team,
          payType: logData.value.payType,
        })
        setTimeout(() => {
          accessCode.value = ''
        }, 800)
      }
    }

    const onChangeLogin = () => {
      router.go(-1)
    }

    const onResend = async () => {
      accessCode.value = ''
      let info = null
      let encryptBody = null
      if (loginData.value.type === 'PHONE_NUMBER') {
        info = {
          phone_number: loginData.value.fullPhone,
        }
        encryptBody = encrypt(info)
        await sendCodeSMSUser(encryptBody)
      } else {
        info = {
          email: loginData.value.code,
        }
        encryptBody = encrypt(info)
        await sendCodeEmailUser(encryptBody)
      }
    }

    onMounted(async () => {
      Object.assign(logData.value, currentLogData.value)
      logData.value.loginRoute = 'LOGIN_PAGE'
      loginData.value = logData.value
      startTimer()
    })

    return {
      form,
      loginData,
      isFormValid,
      onAnotherAccount,
      totalInput,
      completed,
      accessCode,
      onLoginPassword,
      formatPhoneNumber,
      formatEmail,
      formatTime,
      timeLeft,
      onLoginCode,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      loginSubmit,
      onChangeLogin,
      onResend,
      formatNumber,

      t,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
@import '@core/preset/preset/pages/auth-code.scss';
</style>
